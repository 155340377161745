import React from 'react';
import PropTypes from 'prop-types';
import { Layout, SEO } from '@components';
import {
  ContentHeader,
  PrivacyContentLayout,
} from '@home-page';

function TermsOfUse({ location }) {
  return (
    <Layout location={location}>
      <SEO />
      <ContentHeader title="Privacy Policy" />
      <PrivacyContentLayout location={location} />
    </Layout>
  );
}

TermsOfUse.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default TermsOfUse;
